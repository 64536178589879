<template>
  <div class="car-list">
    <div class="car-list-item" v-for="(item, index) in list" :key="index">
      <div class="line-1">
        <div class="car-list-item-title">
          {{ item.product_nm || item.car_product_nm }}
        </div>
        <div class="car-list-item-code">产品编号：{{ item.product_bn }}</div>
      </div>
      <div class="line-2">
        <div class="line-2-left">
          <el-image
            class="line-2-left-img"
            fit="cover"
            :src="item.product_img"
            alt
          ></el-image>
          <div class="line-2-left-tips" :title="`服务时间：${item.service_time}  服务里程：${item.service_mileage}`">
            服务时间：{{ item.service_time }}  服务里程：{{item.service_mileage}}
          </div>
        </div>
        <div class="line-2-right">
          <table class="line-2-right-table">
            <thead>
              <tr>
                <td>车型</td>
                <td>乘客数</td>
                <td>行李位</td>
                <td>价格</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(elem, index) in item.car_type" :key="index">
                <td>{{ elem.car_type_nm }}</td>
                <td>{{ elem.car_passenger_num }}</td>
                <td>{{ elem.car_luggage_num }}</td>
                <td>
                  <span class="price">{{ elem.car_order_car_price }}</span>
                </td>
                <td>
                  <router-link
                    :to="{
                      path: '/rent-car/detail',
                      query: {
                        id: item.id,
                        product_desc: item.product_desc,
                        product_nm: item.product_nm,
                        service_mileage: item.service_mileage,
                        service_time: item.service_time,
                        city_nm: item.city_nm,
                        car_luggage_num: elem.car_luggage_num,
                        car_type_nm: elem.car_type_nm,
                        car_order_car_price: elem.car_order_car_price,
                        car_luggage_num: elem.car_luggage_num,
                        car_passenger_num: elem.car_passenger_num,
                        car_item_id: elem.car_item_id,
                        car_car_insure: elem.car_car_insure,
                        product_bn: item.product_bn
                      }
                    }"
                    class="rent-car-button"
                    >我要包车</router-link
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarList",
  props: {
    list: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
.car-list {
  &-item {
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 1);
    overflow: hidden;

    .line-1 {
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      height: 57px;
      line-height: 55px;
      border-bottom: 2px solid rgba(245, 245, 245, 1);
    }
    .line-2 {
      padding: 25px 15px;
      display: flex;
      &-left {
        width: 36%;
        margin-right: 45px;
        position: relative;
        height: 222px;
        &-img {
          width: 100%;
          height: 100%;
        }
        &-tips {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 28px;
          background: rgba(0, 0, 0, 0.55);
          font-size: 14px;
          padding: 0 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(255, 254, 254, 1);
          line-height: 28px;
        }
      }
      &-right {
        flex: 1;
        &-table {
          width: 100%;
          border: 1px solid rgba(245, 245, 245, 1);
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          text-align: center;
          border-collapse: collapse;
          thead {
            height: 46px;
            line-height: 46px;
            border-bottom: 1px solid rgba(245, 245, 245, 1);
          }
          tbody {
            tr:nth-child(even) {
              height: 40px;
              line-height: 40px;
              background: rgba(245, 245, 245, 1);
            }
            tr:nth-child(odd) {
              height: 56px;
              line-height: 56px;
            }
            .price {
              color: #fc1010;
            }
            .rent-car-button {
              cursor: pointer;
              display: inline-block;
              width: 83px;
              text-align: center;
              height: 32px;
              line-height: 32px;
              background: rgba(91, 9, 123, 1);
              border-radius: 16px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  &.collect {
    .car-list-item {
      .line-2 {
        .line-2-left {
          height: 148px;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
