<template>
  <div class="PersonalCenter">
    <bread-crumb :list="breadCrumbList"></bread-crumb>
    <div class="main">
      <div class="left">
        <div class="left_title">我的会员中心</div>
        <ul>
          <li>
            <div class="menu_title">订单管理</div>
            <ul class="menu_list">
              <li>
                <a @click="index = 1">线路订单</a>
              </li>
              <li>
                <a @click="index = 7">预定线路订单</a>
              </li>
              <li>
                <a @click="index = 8">定制线路订单</a>
              </li>
              <li>
                <a @click="index = 2">车导服务订单</a>
              </li>
            </ul>
          </li>
          <li>
            <div class="menu_title">我的收藏</div>
            <ul class="menu_list">
              <li>
                <a @click="index = 3">线路收藏</a>
              </li>
              <li>
                <a @click="index = 4">车导服务收藏</a>
              </li>
            </ul>
          </li>
          <li>
            <div class="menu_title">账号管理</div>
            <ul class="menu_list">
              <li>
                <a @click="index = 5">修改昵称</a>
              </li>
              <li>
                <a @click="index = 6">修改密码</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="center">
        <div class="center_top">
          <img :src="require('@/assets/index/header.png')" alt />
          <span class="tip">您好!</span>
          <span class="user">{{ this.home.userInfo.user_nm }}</span>
          <span class="back" @click="logout">[退出登陆]</span>
        </div>
        <div class="center_main">
          <div class="center_main_box" v-show="index == 1">
            <div
              @click="jumpOrder(item)"
              class="lineOrderbox"
              v-for="item in lineOrderData"
              :key="item.id"
            >
              <router-link :to="{ path: '/travel-route/order', query: item }">
                <div class="lineOrderId">订单号：{{ item.id }}</div>
                <div class="lineOrderInfo">
                  <img :src="`${$imgBaseURL}${item.route_s_img}`" alt />
                  <div class="linOrderRight">
                    <div class="lineOrderAddress">
                      出发地：{{ item.geton_city_nm }} 离团地：{{ item.end_city_nm }} 行程{{
                        diffDays(item.route_beg_date, item.route_end_date)
                      }}天 | {{ item.adult_num + item.children_num }}人行
                    </div>
                    <div class="lineOrderTitle">{{ item.route_title }}</div>
                    <div class="lineOrderDes">
                      {{ item.special_needs }}
                      编号：{{ item.route_bn }}
                    </div>
                    <div class="lineOrderOther">
                      <div class="date">
                        出行日期：{{ item.route_beg_date | timeFormat }}
                      </div>
                      <div class="price">
                        价格：
                        <span>{{ item.base_money }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
            <pagination
              background
              @current-change="lineOrderChange"
              :current-page.sync="lineOrderRequest.page"
              :page-size="lineOrderRequest.per_page"
              layout="prev, pager, next, jumper"
              :total="lineOrderRequest.total_nums"
              v-if="lineOrderRequest.total_nums != 0"
            ></pagination>
          </div>
          <div class="center_main_box" v-show="index == 2">
            <div
              class="carOrderList"
              v-for="item in carOrderData"
              :key="item.id"
            >
              <router-link
                :to="{
                  path: '/rent-car/detail',
                  query: { ...item, target: 'personal' },
                }"
              >
                <div class="carOrderId">订单号：{{ item.id }}</div>
                <div class="carOrderBox">
                  <img :src="`${item.route_s_img}`" alt />
                  <div class="carOrderInfo">
                    <div class="date">
                      包车日期：{{ item.order_date | timeFormat }}
                    </div>
                    <div class="carOrderDes">
                      <div class="name">包车人姓名：{{ item.car_user_nm }}</div>
                      <div class="phone">电话：{{ item.car_user_tel }}</div>
                    </div>
                    <div class="carOrderAdderss">
                      <div>上车地点：{{ item.get_on_place }}</div>
                      <div>下车地点：{{ item.get_down_place }}</div>
                    </div>
                    <div class="price">价格：{{item.order_car_price}}</div>
                  </div>
                </div>
              </router-link>
            </div>
            <pagination
              background
              @current-change="carOrderChange"
              :current-page.sync="carOrderRequest.page"
              :page-size="carOrderRequest.per_page"
              layout="prev, pager, next, jumper"
              :total="carOrderRequest.total_nums"
              v-if="carOrderRequest.total_nums != 0"
            ></pagination>
          </div>
          <div class="center_main_box" v-show="index == 3">
            <route-list :list="lineList" class="collect">
              <pagination
                background
                @current-change="lineCurrentChange"
                :current-page.sync="lineForm.page"
                :page-size="lineForm.per_page"
                layout="prev, pager, next, jumper"
                :total="lineTotalPage"
              ></pagination>
            </route-list>
          </div>
          <div class="center_main_box" v-show="index == 4">
            <car-list :list="carList" class="collect"></car-list>
            <pagination
              background
              @current-change="carCurrentChange"
              :current-page.sync="carForm.page"
              :page-size="carForm.per_page"
              layout="prev, pager, next, jumper"
              :total="carTotalPage"
            ></pagination>
          </div>
          <div class="center_main_box" v-show="index == 5">
            <el-form
              ref="ruleForm2"
              label-width="80px"
              :model="formName"
              :rules="nameRules"
            >
              <el-form-item label="修改昵称" prop="name">
                <el-input v-model="formName.name"></el-input>
              </el-form-item>
              <el-button type="warning" @click="editNockName">确 认</el-button>
            </el-form>
          </div>
          <div class="center_main_box" v-show="index == 6">
            <el-form
              ref="ruleForm"
              :model="formPass"
              label-width="80px"
              :rules="passRules"
            >
              <el-form-item label="旧密码" prop="oldPssword">
                <el-input
                  type="password"
                  v-model="formPass.oldPssword"
                ></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="nePassword">
                <el-input
                  type="password"
                  autocomplete="off"
                  v-model="formPass.nePassword"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="rePassword">
                <el-input
                  type="password"
                  autocomplete="off"
                  v-model="formPass.rePassword"
                ></el-input>
              </el-form-item>
              <el-button type="warning" @click="editPasswordFunc()"
                >确 认</el-button
              >
            </el-form>
          </div>
          <div class="center_main_box" v-show="index == 7">
            <div
              @click="jumpDetial(item, 1)"
              class="box-7"
              v-for="item in scheduledLineOrderList"
              :key="item.id"
            >
              <div class="box-7-t">
                <span>订单编号 {{ item.id }}</span>
              </div>

              <div class="box-7-f">
                <img :src="`${$imgBaseURL}${item.route_s_img}`" alt />
                <div>
                  <div>
                    <span class="box-7-l">出行开始日期:</span>
                    <span>{{ item.beg_date | rTime }}</span>
                  </div>
                  <div>
                    <p>
                      <span class="box-7-l">儿童数占床:</span>
                      <span>{{ item.children_b_num }}</span>
                    </p>
                    <p>
                      <span class="box-7-l">儿童数不占床:</span>
                      <span>{{ item.children_s_num }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span class="box-7-l">姓名:</span>
                      <span
                        >{{ item.customer_name_f }}
                        {{ item.customer_name_l }}</span
                      >
                    </p>

                    <p>
                      <span class="box-7-l">成人人数:</span>
                      <span>{{ item.travel_num }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span class="box-7-l">email:</span>
                      <span>{{ item.customer_email }}</span>
                    </p>

                    <p>
                      <span class="box-7-l">微信:</span>
                      <span>{{ item.customer_wx }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span class="box-7-l">电话:</span>
                      <span>{{ item.customer_tel }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <pagination
              background
              @current-change="getScheduledLineOrder"
              :current-page.sync="scheduledLineOrderData.page"
              :page-size="scheduledLineOrderData.per_page"
              layout="prev, pager, next, jumper"
              :total="scheduledLineOrderData.total_nums"
              v-if="scheduledLineOrderData.total_nums != 0"
            ></pagination>
          </div>
          <div class="center_main_box" v-show="index == 8">
            <div
              @click="jumpDetial(item, 2)"
              class="box-7"
              v-for="item in cus_route_data"
              :key="item.id"
            >
              <div class="box-7-t">
                <span>订单编号 {{ item.id }}</span>
              </div>

              <div class="box-7-f">
                <img :src="`${$imgBaseURL}${item.route_s_img}`" alt />
                <div>
                  <div>
                    <span class="box-7-l">出行开始日期:</span>
                    <span>{{ item.beg_date | rTime }}</span>
                  </div>
                  <div>
                    <p>
                      <span class="box-7-l">儿童数占床:</span>
                      <span>{{ item.children_b_num }}</span>
                    </p>
                    <p>
                      <span class="box-7-l">儿童数不占床:</span>
                      <span>{{ item.children_s_num }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span class="box-7-l">姓名:</span>
                      <span
                        >{{ item.customer_name_f }}
                        {{ item.customer_name_l }}</span
                      >
                    </p>

                    <p>
                      <span class="box-7-l">成人人数:</span>
                      <span>{{ item.travel_num }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span class="box-7-l">email:</span>
                      <span>{{ item.customer_email }}</span>
                    </p>

                    <p>
                      <span class="box-7-l">微信:</span>
                      <span>{{ item.customer_wx }}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      <span class="box-7-l">电话:</span>
                      <span>{{ item.customer_tel }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <pagination
              background
              @current-change="get_cus_route_order_search_by"
              :current-page.sync="cus_route_form.page"
              :page-size="cus_route_form.per_page"
              layout="prev, pager, next, jumper"
              :total="cus_route_form.total_nums"
              v-if="cus_route_form.total_nums != 0"
            ></pagination>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="box-4">
          <router-link
            :to="{
              path: '/travel-route/detail',
              query: { route_bn: item.route_bn },
            }"
            class="box-4-item"
            v-for="(item, index) in adData"
            :key="index"
          >
            <img :src="item.ad_img" alt class="box-4-item-icon" />
            <div class="box-4-item-desc" :title="item.route_title">{{ item.route_title }}</div>
            <div class="box-4-item-price">
              <span class="price">{{ item.route_price }}</span
              >起
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imgBaseURL } from '@/config/index'
import moment from 'moment';
import BreadCrumb from '@/components/BreadCrumb';
import RouteList from '@/components/RouteList';
import CarList from '@/components/CarList';
import pagination from '@/components/pagination';
import { mapState, mapActions } from 'vuex';
import {
  userCarSearch,
  userRouteSearch,
  routeTagDetailSearch,
  editInfo,
  editPassword,
  routeOrderSearchBy,
  carOrderSearchBy,
  travel_route_reserve_search_by, // 查询预定线路订单
  cus_route_order_search_by, // 查询定制线路订单
  getUserInfoByToken // 通过token获取用户信息
} from '@/api/index';
export default {
  name: 'PersonalCenter',
  components: {
    BreadCrumb,
    RouteList,
    pagination,
    CarList,
  },
  filters: {
    timeFormat(value) {
      let resultValue = moment(value)
        // .subtract(1, "days")
        .format('YYYY年MM月DD日');
      return resultValue;
    },
    rTime: function (value) {
      var json_date = new Date(value).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '');
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.formPass.rePassword !== '') {
          this.$refs.ruleForm.validateField('rePassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.formPass.nePassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'));
      }
      callback();
    };
    return {
      breadCrumbList: [],
      index: 1,
      name: 'personalCenter',
      formPass: {
        oldPssword: '',
        nePassword: '',
        rePassword: '',
      },
      passRules: {
        oldPssword: [
          { required: true, validator: validatePass3, trigger: 'blur' },
        ],
        nePassword: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        rePassword: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
      formName: {
        name: '',
      },
      nameRules: {
        oldPssword: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
        ],
      },
      lineOrderData: [
        {
          route_id: '',
          route_s_img: '',
          route_beg_date: '',
          route_end_date: '',
          adult_num: '',
          children_num: '',
          base_money: '',
          geton_city_bn: '',
          geton_city_nm: '',
          geton_place: '',
          geton_time: '',
          geton_detail_addr: '',
          is_advance_hotel: '',
          is_late_hotel: '',
          is_airport_pickup: '',
          airport_pickup_flight: '',
          airport_pickup_time: '',
          is_transportation: '',
          transportation_flight: '',
          transportation_time: '',
          special_needs: '',
          bill_user_surname: '',
          bill_user_name: '',
          bill_user_sex: '',
          bill_user_email: '',
          bill_user_tel: '',
          bill_date: '',
          pay_status: '',
          bil_status: '',
          opet_demo: '',
          orderid: '1111111111111111111',
        },
      ],
      carOrderData: [],
      // 线路订单 -- 查询条件
      lineOrderRequest: {
        page: 1,
        per_page: 5,
        pay_status: '',
        bill_status: '',
        route_beg_date_b: '',
        route_beg_date_e: '',
        total_nums: 0,
        cur_user_id: ""
      },
      // 预定线路订单 -- 查询条件
      scheduledLineOrderData: {
        page: 1,
        per_page: 5,
        pay_status: '',
        bill_status: '',
        route_beg_date_b: '',
        route_beg_date_e: '',
        total_nums: 0,
        cur_user_id: ""
      },
      // 定制线路订单 -- 查询条件
      cus_route_form: {
        page: 1,
        per_page: 5,
        pay_status: '',
        bill_status: '',
        route_beg_date_b: '',
        route_beg_date_e: '',
        total_nums: 0,
        cur_user_id: ""
      },
      // 车导服务订单 -- 查询条件
      carOrderRequest: {
        page: 1,
        per_page: 5,
        pay_status: '',
        bill_status: '',
        route_beg_date_b: '',
        route_beg_date_e: '',
        total_nums: 0,
        cur_user_id: ""
      },
      carForm: {
        page: 1,
        per_page: 5,
        id: '',
        user_id: '',
        cur_user_id: ""
      },
      lineForm: {
        page: 1,
        per_page: 5,
        id: '',
        user_id: '',
        currency: '',
        cur_user_id: ""
      },
      // 线路
      lineTotalPage: 0,
      lineList: [],
      scheduledLineOrderList: [],
      cus_route_data: [],
      // 车导
      carTotalPage: 0,
      carList: [],
      adData: [],
      imgBaseURL: imgBaseURL
    };
  },
  computed: {
    ...mapState(['home']),
    currency() {
      return this.$store.state.home.currency.currency_id;
    },
  },
  watch: {
    currency: {
      handler(val) {
        this.lineOrderRequest.currency = Number(val);
        this.scheduledLineOrderData.currency = Number(val);
        this.cus_route_form.currency = Number(val);
        this.carOrderRequest.currency = Number(val);
        this.carForm.currency = Number(val);
        this.lineForm.currency = Number(val);
        // 查询条件赋值uid
        const uid = this.home.userInfo.uid;
        this.lineOrderRequest.cur_user_id = uid;
        this.scheduledLineOrderData.cur_user_id = uid;
        this.cus_route_form.cur_user_id = uid;
        this.carOrderRequest.cur_user_id = uid;
        this.carForm.cur_user_id = uid;
        this.lineForm.cur_user_id = uid;

        // 获取线路订单
        this.routeOrderSearchByFunc();
        // 获取预定线路订单
        this.getScheduledLineOrder();
        // 获取定制线路订单
        this.get_cus_route_order_search_by();
        // 获取车导订单
        this.carOrderSearchByFunc();
        // 获取车导服务收藏
        this.getUserCarSearch();
        // 获取线路收藏
        this.getUserRouteSearch();
      },
      immediate: true,
    },
  },
  created() {
    this.formName.name = this.home.userInfo.user_nm; // 修改昵称输入框赋值
    this.index = this.$route.query.index || 1;
    this.lineForm.currency = this.home.currency.currency_id;
    this.breadCrumbList = [
      {
        name: '会员中心',
        path: '',
        query: '',
      },
    ];
    // 获取广告
    this.getAdData();
  },

  methods: {
    ...mapActions(['changSetToken', 'changSetInfo']),

    get_cus_route_order_search_by() {
      cus_route_order_search_by(this.cus_route_form).then((res) => {
        this.cus_route_data = res.data;
        this.cus_route_form.total_nums = res.total_nums;
      });
    },

    jumpDetial(item, type) {
      this.$router.push({
        name: 'travelRouteDetail',
        query: {
          id: item.id,
          route_bn: item.route_bn,
          type: type,
        },
      });
    },

    jumpOrder(info) {
      this.$router.push({
        name: 'travelOrderList',
        query: {
          id: info.id,
          route_bn: info.route_bn,
        },
      });
    },

    // 相差天数
    diffDays(beg, end) {
      return moment(end).diff(moment(beg), 'days');
    },
    // 获取线路订单
    routeOrderSearchByFunc() {
      routeOrderSearchBy(this.lineOrderRequest)
        .then((res) => {
          this.lineOrderData = res.data;
          this.lineOrderRequest.total_nums = res.total_nums;
        })
        .catch(() => {});
    },
    // 获取车导订单
    carOrderSearchByFunc() {
      carOrderSearchBy(this.carOrderRequest)
        .then((res) => {
          console.log(res);
          this.carOrderData = res.data;
          res.data.forEach(e => {
            if (e.route_s_img.indexOf(this.imgBaseURL) < 0 ) {
              e.route_s_img = this.imgBaseURL + e.route_s_img;
            }
          })
          this.carOrderRequest.total_nums = res.total_nums;
        })
        .catch(() => {});
    },
    // 修改昵称
    editNockName() {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          editInfo({
            id: this.home.userInfo.uid,
            user_nm: this.formName.name,
          }).then((response) => {
            this.$message({
              message: response.message,
              type: 'success',
            });
            // 修改昵称成功，更新vuex用户信息
            getUserInfoByToken({token: this.home.token}).then((res) => {
              this.changSetInfo(res.data[0]);
            });
          });
        } else {
          return false;
        }
      });
    },
    // 修改密码
    editPasswordFunc() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          editPassword({
            id: this.home.userInfo.uid,
            old_psw: this.formPass.oldPssword,
            new_psw: this.formPass.nePassword,
          }).then((res) => {
            if (res && res.code === 200) {
              this.$message({
                message: "修改密码成功，请重新登录",
                type: 'success',
              });
              // 清除token，跳转登录页
              this.changSetToken('');
              this.$router.push({
                path: '/login/login',
              });
            } else {
              this.$message({
                message: "修改失败",
                type: 'error',
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 没有token,回到首页
    goToHome() {
      if (!this.home.token) {
        this.$router.push({
          path: '/',
        });
      }
    },
    // 子页-右侧广告
    getAdData() {
      routeTagDetailSearch({ tagid: 'tag_type_101' }).then((res) => {
        this.adData = res.data;
      });
    },
    getUserCarSearch() {
      userCarSearch(this.carForm).then((response) => {
        this.carList = response.data;
        this.carTotalPage = response.total_page * this.carForm.per_page;
      });
    },
    getUserRouteSearch() {
      userRouteSearch(this.lineForm).then((response) => {
        this.lineList = response.data;
        this.lineTotalPage = response.total_page * this.lineForm.per_page;
      });
    },

    getScheduledLineOrder() {
      travel_route_reserve_search_by(this.scheduledLineOrderData).then(
        (res) => {
          this.scheduledLineOrderData.total_nums = res.total_nums;
          this.scheduledLineOrderList = res.data;
        }
      );
    },

    // 线路收藏分页
    lineCurrentChange(val) {
      this.lineForm.page = val;
      this.getUserRouteSearch();
    },
    // 线路收藏分页
    carCurrentChange(val) {
      this.carForm.page = val;
      this.getUserCarSearch();
    },
    // 退出
    logout() {
      this.changSetToken('');
      this.$router.push({
        path: '/',
      });
    },
    // 车导订单分页
    carOrderChange(val) {
      this.carOrderRequest.page = val;
      this.carOrderSearchByFunc();
    },
    // 线路订单分页
    lineOrderChange(val) {
      this.carOrderRequest.page = val;
      this.lineOrderSearchByFunc();
    },
  },
};
</script>

<style scoped lang="scss">
.carOrderList,
.lineOrderbox {
  margin-bottom: 20px;
  background: #fff;
  padding: 10px;
}
:deep(.el-form) {
  background: #fff;
}
.PersonalCenter {
  background: #f5f5f5;
  :deep(.top) {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
  }
  :deep(.left-bottom-item-right-w) {
    -webkit-line-clamp: 1;
  }

  .main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 500px;
    width: 1200px;
    margin: 0 auto;
    .left {
      width: 226px;
      background: #fff;
      .left_title {
        padding-left: 20px;
        height: 68px;
        background: rgba(111, 15, 149, 1);
        line-height: 68px;
        color: #fff;
        font-size: 24px;
      }
      .menu_title {
        padding-left: 20px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 40px;
      }
      .menu_list {
        padding-left: 20px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 35px;
        li {
          padding-left: 10px;
        }
      }
    }
    .center {
      width: 671px;
      margin: 0 17px;
      min-height: 500px;

      .box-7 {
        background: #fff;
        padding: 10px;
        cursor: pointer;
        :deep(.el-row) {
          margin-bottom: 10px;
        }
        .box-7-f {
          display: flex;
          justify-content: space-between;
          align-items: center;
          :deep(.el-row) {
            width: 450px;
          }
          & > div > div {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 10px;
            p {
              width: 200px;
            }
          }
          img {
            width: 190px;
            height: 150px;
          }
        }

        .box-7-t {
          background: #fff;
          margin-bottom: 10px;
          font-size: 16px;
          color: #999;
        }
      }

      .box-7-l {
        color: #333333;
      }

      .center_top {
        background: #fff;
        margin-bottom: 14px;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          margin-right: 20px;
        }
        .tip {
          font-size: 24px;
          margin-right: 10px;
        }
        .user {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #3d7eff;
        }
        .back {
          cursor: pointer;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-left: 10px;
        }
      }
      .center_main {
        .lineOrderbox {
          .linOrderRight {
            width: calc(100% - 200px);
          }
          .lineOrderTitle {
            margin-bottom: 5px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 26px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            width: 100%;
          }
          .lineOrderAddress {
            vertical-align: middle;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
          }
          .lineOrderInfo {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .lineOrderDes {
            margin-bottom: 5px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            width: 100%;
          }
          img {
            width: 190px;
            border: none;
            height: 150px;
            background: white;
          }
          .lineOrderId {
            padding-bottom: 10px;
            font-size: 15px;
            color: #999;
          }

          .lineOrderOther {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 20px;
            .date {
              color: #333333;
            }
            .price {
              color: red;
              font-weight: 600;
              font-size: 17px;
            }
          }
        }

        .carOrderId {
          margin-bottom: 10px;
          color: #999;
        }
        .carOrderInfo {
          width: calc(100% - 200px);
          div {
            margin-bottom: 5px;
            color: #333333;
          }
          .price {
            color: red;
            font-size: 15px;
            font-weight: 600;
            margin-top: 20px;
          }
          .date {
            margin-bottom: 10px;
          }
        }

        .carOrderDes,
        .carOrderAdderss {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          div {
            margin-right: 20px;
          }
        }

        .carOrderBox {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          img {
            width: 190px;
            border: none;
            height: 150px;
            background: white;
            margin-right: 30px;
          }
        }

        overflow: hidden;
        // background: #fff;
        .center_main_box {
          // padding: 10px;
          .el-form {
            padding: 40px;
            .el-button {
              margin: 0 auto;
              display: block;
            }
          }
        }
      }
    }
    .right {
      width: 254px;
      background: #888;
      .box-4 {
        background: rgba(255, 255, 255, 1);
        padding: 10px;
        &-title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 24px;
          margin: 2px 0 10px;
        }
        &-item {
          border-bottom: 2px solid rgba(245, 245, 245, 1);
          margin-bottom: 14px;
          &:last-child {
            border: none;
          }
          &-icon {
            height: 135px;
            width: 100%;
            margin-bottom: 10px;
          }
          &-desc {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            line-height: 18px;
            margin-bottom: 15px;
            height: 165px;
            overflow:hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 9; // 多行在这里修改数字即可，这里显示9行
            overflow:hidden;
            -webkit-box-orient: vertical;
          }
          &-price {
            .price {
              color: rgba(255, 0, 0, 1);
            }
            padding-bottom: 15px;

            text-align: right;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>