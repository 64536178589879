var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"car-list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"car-list-item"},[_c('div',{staticClass:"line-1"},[_c('div',{staticClass:"car-list-item-title"},[_vm._v(" "+_vm._s(item.product_nm || item.car_product_nm)+" ")]),_c('div',{staticClass:"car-list-item-code"},[_vm._v("产品编号："+_vm._s(item.product_bn))])]),_c('div',{staticClass:"line-2"},[_c('div',{staticClass:"line-2-left"},[_c('el-image',{staticClass:"line-2-left-img",attrs:{"fit":"cover","src":item.product_img,"alt":""}}),_c('div',{staticClass:"line-2-left-tips",attrs:{"title":`服务时间：${item.service_time}  服务里程：${item.service_mileage}`}},[_vm._v(" 服务时间："+_vm._s(item.service_time)+" 服务里程："+_vm._s(item.service_mileage)+" ")])],1),_c('div',{staticClass:"line-2-right"},[_c('table',{staticClass:"line-2-right-table"},[_vm._m(0,true),_c('tbody',_vm._l((item.car_type),function(elem,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(elem.car_type_nm))]),_c('td',[_vm._v(_vm._s(elem.car_passenger_num))]),_c('td',[_vm._v(_vm._s(elem.car_luggage_num))]),_c('td',[_c('span',{staticClass:"price"},[_vm._v(_vm._s(elem.car_order_car_price))])]),_c('td',[_c('router-link',{staticClass:"rent-car-button",attrs:{"to":{
                    path: '/rent-car/detail',
                    query: {
                      id: item.id,
                      product_desc: item.product_desc,
                      product_nm: item.product_nm,
                      service_mileage: item.service_mileage,
                      service_time: item.service_time,
                      city_nm: item.city_nm,
                      car_luggage_num: elem.car_luggage_num,
                      car_type_nm: elem.car_type_nm,
                      car_order_car_price: elem.car_order_car_price,
                      car_luggage_num: elem.car_luggage_num,
                      car_passenger_num: elem.car_passenger_num,
                      car_item_id: elem.car_item_id,
                      car_car_insure: elem.car_car_insure,
                      product_bn: item.product_bn
                    }
                  }}},[_vm._v("我要包车")])],1)])}),0)])])])])}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("车型")]),_c('td',[_vm._v("乘客数")]),_c('td',[_vm._v("行李位")]),_c('td',[_vm._v("价格")]),_c('td')])])
}]

export { render, staticRenderFns }