<template>
  <div class="left-bottom">
    <router-link
      :to="{ path: '/travel-route/detail', query: {route_bn: item.route_bn}}"
      class="left-bottom-item"
      v-for="item in list"
      :key="item.id"
    >
      <el-image :src="`${$imgBaseURL}${item.img}`" class="left-bottom-item-left">
        <div slot="error" class="image-slot">
          <img :src="require('@/assets/index/1.jpg')" alt />
        </div>
      </el-image>
      <div class="left-bottom-item-right">
        <div>
          <div class="left-bottom-item-right-q">
            <i class="icon-dizhi"></i>
            <span
              class="text"
            >出发地：{{item.start_city_nm}}&nbsp;&nbsp;&nbsp;离团地：{{item.end_city_nm}}&nbsp;&nbsp;&nbsp;行程{{item.travel_days}}天 | {{item.travel_num}}人行</span>
          </div>
          <div class="left-bottom-item-right-w" :title=item.route_nm>{{item.route_nm}}</div>
          <div class="left-bottom-item-right-e" :title=item.route_demo>{{item.route_demo}}</div>
        </div>
        <div class="left-bottom-item-right-r">
          <span class="left-bottom-item-right-r-left">
            {{item.join_type_nm}}--
            <span class="time">{{item.regular_type_nm}}</span>
          </span>
          <span class="price">价格：{{item.price}}</span>
        </div>
      </div>
    </router-link>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'RouteList',
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.left-bottom {

  &-item {
    margin-bottom: 18px;
    width: 100%;
    height: 251px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    
    &-left {
      width: 40.8%;
      border: none;
      height: 100%;
      background: rgba(255, 255, 255, 1);
      float: left;
    }
    &-right {
      flex: 1;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-q {
        margin-bottom: 5px;
        font-size: 0;
        .icon-dizhi {
          display: inline-block;
          vertical-align: middle;
          width: 16px;
          height: 16px;
          background: url('../assets/index/icon-dizhi.png') no-repeat;
          background-size: contain;
        }
        .text {
          vertical-align: middle;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          line-height: 18px;
        }
      }

      &-w {
        margin-bottom: 5px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 26px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
      }
      &-e {
        margin-bottom: 5px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 24px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
      }
      &-r {
        height: 35px;
        line-height: 47px;
        border-top: 2px solid #f5f5f5;
        font-size: 0;
        &-left {
          vertical-align: bottom;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(153, 153, 153, 1);
          line-height: 55px;
          .time {
            color: #3d7eff;
          }
        }

        .price {
          float: right;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(255, 0, 0, 1);
        }
      }
    }
  }
  &.collect {
    .left-bottom-item {
      height: 181px;
    }
  }
}
</style>
