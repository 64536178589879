<template functional>
  <el-pagination v-bind="data.attrs" v-on="listeners"></el-pagination>
</template>

<script>
  export default {
    name: 'Pagination'
  };
</script>

<style lang="scss">
.el-pagination {
  margin: 15px 0 30px;
  padding: 0;
  .btn-prev,
  .btn-next {
    width: 34px;
    height: 34px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(221, 221, 221, 1);
    border-radius: 2px;
  }
  .el-pager {
    .number {
      width: 34px;
      height: 34px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(221, 221, 221, 1);
      border-radius: 2px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3d7eff !important;
      line-height: 34px;
      &.active {
        color: rgba(255, 255, 255, 1)!important;
        background: rgba(61, 126, 255, 1);
      }
    }
  }
  .el-pagination__jump {
    height: 34px !important;
    line-height: 34px !important;
    font-size: 14px !important;
    color: #333333 !important;
  }
  .el-input__inner {
    width: 34px;
    height: 34px !important;
    line-height: 34px !important;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(221, 221, 221, 1);
    border-radius: 2px;
  }
}
</style>